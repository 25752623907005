body {
  background: transparent url(../images/bg.jpg) top right no-repeat;
  background-size: auto;
}

main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo {
  width: 40vw;
  margin: 40px 0;
}
